'use client';

export default function GlobalError({ error, reset }) {
  return (
    <html>
      <head>
        <style>
          {`
          body {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: #000;
            color: white;
          }

          .container {
            text-align: center;
          }

          .header {
            font-size: 24px;
            padding: 10px 0;
            color: white;
            line-height: 1.5;
          }

          .paragraph {
            font-size: 16px;
            color: white;
            line-height: 1.5;
          }

          .button {
            background-color: blue;
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            margin-top:20px;
          }
        `}
        </style>
      </head>
      <body>
        <div className="container">
          <h1 className="header">Something went wrong...!</h1>
          <p className="paragraph">
            Error Details:{' '}
            <span id="error" className="paragraph">
              {error.toString()}
            </span>
          </p>
          <button className="button" onClick={() => window.history.back()}>
            Go back
          </button>
        </div>
      </body>
    </html>
  );
}
